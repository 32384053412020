/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Muwafiq components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Muwafiq examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Tooltip, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";

import DeleteIcon from "@mui/icons-material/Delete";
import ShowIcon from "@mui/icons-material/Visibility";

import MDButton from "components/MDButton";
import CsvDownloadButton from 'react-json-to-csv'

import AuthService from "services/auth-service";
import CrudService from "services/cruds-service";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import ProductCell from "components/ProductCell";
import ProductCellWithoutImage from "components/ProductCellWithoutImage";
import FilterAppointment from "./filter-appointment";
import i18n from "i18n";
import moment from "moment";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCardWithoutIcon";

// import {
//   useMaterialUIController,
//   setLimit
// } from "context";

function AppointmentManagement() {
  let { state } = useLocation();
  const format1 = "YYYY-MM-DD HH:mm";

  const ability = useAbility(AbilityContext);
  const { t } = i18n;
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [statusStatistics, setStatusStatistics] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [limitChange, setLimitChange] = useState(false);
  const [sortVal, setSortVal] = useState(null);
  const [sortUrl, setSortUrl] = useState("&sort=-date_created");
  const [isAdmin, setIsAdmin] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [appointmentsInfo, setAppointmentsInfo] = useState(false);
  const [filterUrl, setFilterUrl] = useState(null);
  const [filterUrl1, setFilterUrl1] = useState(null);
  const [defaultFilterUrl, setDefaultFilterUrl] = useState("");
  const [fromStatistics, setFromStatistics] = useState(false);
  const [clinicId, setClinictId] = useState(null);
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });

  
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_URL;



  // const [controller, dispatch] = useMaterialUIController();
  // const {
  //   limit
  // } = controller; 

  // function changeTableLimit(limit) {
  //   setLimit(
  //     dispatch,
  //     limit
  //   );
  // }

  const getAppointments = async (filUrl) => {
    let filUrl1=filUrl ? `${filUrl}&page=${page}` : `&page=${page}`;
    let URL = `${filterUrl1 ? `${filUrl1}${filterUrl1}` : `${filUrl1}`}`;
    const response = await CrudService.getAppointments(URL);
    setData(response.data);
    setMeta(response.meta);
    if(!fromStatistics){
    const statusRes = await CrudService.getAllStatus();
        const allStatus = statusRes.data;
        const StatusArr = [{
                name: t('all'),
                count: response.meta.total_count,
                color: 'black',
                // percentage: `100%`,
              }];
        let allCount =0;
        for (const item in allStatus) {
          if (Object.hasOwnProperty.call(allStatus, item)) {
            const element = allStatus[item];
            const countRes = await CrudService.getCountAppointmentsStatus(
              `&filter[status_id][_eq]=${element.id}${filUrl}`
            );
            console.log(countRes);
            const percentage = (countRes.data[0]?.count?.id/response.meta.total_count)*100;
            countRes.data[0]?.count?.id !== 0 &&
              StatusArr.push({
                name: element.name,
                count: countRes?.data[0]?.count.id,
                color: element.color,
                percentage: `${percentage.toFixed(2)}%`,
                element: element
              });
              allCount += +countRes?.data[0]?.count.id
          }
        }
        StatusArr[0].count=allCount;
        setStatusStatistics(StatusArr);
    }else{
      setFromStatistics(false)
    }
  };

  useEffect(() => {
    (async () => {
      const { data } = await AuthService.getProfile();
      setIsAdmin(
        data.role.name === "Administrator" || data.role.name === "admins"
      );
      setAppointmentsInfo(true);
      setClinictId(data.clinic_id.id);
      if (data.role.name !== "Administrator") {
        setFilterUrl(
          `${filterUrl || ""}&filter[clinic_id][_eq]=${data.clinic_id.id}${sortUrl}`
        );
        setDefaultFilterUrl(`&filter[clinic_id][_eq]=${data.clinic_id.id}${sortUrl}`);
      } else getAppointments(sortUrl);
    })();
  }, []);

  useEffect(() => {
    appointmentsInfo && getAppointments(`${filterUrl || ""}${sortUrl}`);
  }, [page]);

  useEffect(() => {
    appointmentsInfo && getAppointments(`${filterUrl || ""}${sortUrl}`);
    setLimitChange(false);
  }, [limitChange]);

  useEffect(() => {
    // style under constructure ..........
    console.log(sortVal)
    let val;
    if (sortVal) {
      if(sortVal.id === 'campaignData') 
      setSortUrl(`&sort=${sortVal.desc ? "-" : "+"}source&sort=${sortVal.desc ? "-" : "+"}campaign&sort=${sortVal.desc ? "-" : "+"}medium&sort=${sortVal.desc ? "-" : "+"}content&sort=${sortVal.desc ? "-" : "+"}page`);
      else{
        if(sortVal.id === 'responsible') val = 'responsible_id'
        if(sortVal.id === 'pationt') val = 'pationt_name'
        if(sortVal.id === 'commingDate') val = 'comming_date_time'
        else val = sortVal.id
        setSortUrl(`&sort=${sortVal.desc ? "-" : "+"}${val}`);
      }
    }
  }, [sortVal]);

  useEffect(() => {
    if (appointmentsInfo && page === 1)
      getAppointments(`${filterUrl || ""}${sortUrl}`);
    else if (appointmentsInfo && page !== 1) setPage(1);
  }, [filterUrl, sortUrl, filterUrl1]);

  useEffect(() => {
    setTableData(getRows(data));
  }, [data]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickShowFollowUpHandler = (id, clinicId) => {
    (ability.can("edit", "appointments") || isAdmin) && navigate(`/appointmentManagement/followUps/${id}/${clinicId}`);
  };

  // const clickDeleteHandler = async (e, id) => {
  //   try {
  //     if (!confirm(t("confirmDelete"))) {
  //       e.nativeEvent.stopImmediatePropagation();
  //     } else {
  //       /*
  //       you must delete all follow up in this appointment 
  //       .....
  //       .....
  //       */
  //       await CrudService.deleteAppointment(id);
  //       // the delete does not send a response
  //       // so I need to get again the appointments to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
  //       const response = await CrudService.getAppointments(filterUrl);
  //       setData(response.data);
  //       setNotification({
  //         value: true,
  //         text: t("deleteSuccessfully"),
  //       });
  //     }
  //   } catch (err) {
  //     // it sends error is the category is associated with an item
  //     console.error(err);
  //     if (err.hasOwnProperty("errors")) {
  //       setNotification({
  //         value: true,
  //         text: err.errors[0].title,
  //       });
  //     }
  //     return null;
  //   }
  // };

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      console.log(moment(row.date_created).format(format1),row.date_created);
      return {
        id: row.id, 
        date_created: moment(row.date_created).add(-3,'hour').format(format1),
        responsible: row.responsible_id,
        pationt: [row.pationt_id.first_name, row.pationt_id.phone],
        clinic: row.clinic_id.name,
        smsource: row.source_id.name,
        details: row.details,
        gclid: row.gclid,
        fbclid: row.fbclid,
        req_body: row.req_body,
        campaignData: `${row.source?`${row.source} ,`: ''}
        ${row.campaign?`${row.campaign}, `: ''}
        ${row.medium?`${row.medium}, `: ''}
        ${row.term?`${row.term}, `: ''}
        ${row.content?`${row.content}, `: ''}`,
        clinicId: row.clinic_id,
        status: row.status_id,
        commingDate: row.comming_date_time && moment(row.comming_date_time).format(format1)
        //row.its_follow_up[row.its_follow_up.length - 1]?.comming_date_time?moment(row.its_follow_up[row.its_follow_up.length - 1]?.comming_date_time).format(format1):'',
      };
    });
    return updatedInfo;
  };

  const dataTableData = {
    columns: [
      {
        Header: t("pationt"),
        accessor: "pationt",
        Cell: (info) => {
          return (
            <MDBox onClick={() =>
                clickShowFollowUpHandler(
                  info.cell.row.original.id,
                  info.cell.row.original.clinicId.id
              )} sx={{ cursor: 'pointer' }}>
              <ProductCellWithoutImage array={info.cell.row.original.pationt} />
            </MDBox>
          );
        },
      },
      {
        Header: t("status"),
        accessor: "status",
        Cell: (info) => {
          return (
              <MDBox
                sx={{
                  backgroundColor: info.cell.row.values.status.color,
                  borderRadius: 16,
                  cursor: 'pointer'
                }}
                px={2}
                onClick={() =>
                clickShowFollowUpHandler(
                  info.cell.row.original.id,
                  info.cell.row.original.clinicId.id
              )}
              >
                <MDTypography variant="caption" color="white">
                  {info.cell.row.values.status.name}
                </MDTypography>
              </MDBox>
          );
        },
      },
      {
        Header: t("date_created"),
        accessor: "date_created",
        Cell: (info) => {
          // console.log(info.cell.row.original.date_created,info.cell.row.original.date_created.split(" "));
          return (
            <MDBox display='grid' sx={{ cursor: 'pointer' }}>
              <MDTypography 
                variant="button" 
                fontWeight="medium" 
                onClick={() =>
                  clickShowFollowUpHandler(
                    info.cell.row.original.id,
                    info.cell.row.original.clinicId.id
                )}>
                  {info.cell.row.original.date_created.split(" ")[0]}
              </MDTypography>
              <MDTypography 
                variant="caption" 
                fontWeight="medium" 
                onClick={() =>
                  clickShowFollowUpHandler(
                    info.cell.row.original.id,
                    info.cell.row.original.clinicId.id
                )}>
                  {info.cell.row.original.date_created.split(" ")[1]}
              </MDTypography>
            </MDBox>
          )}
      },
      {
        Header: t("details"),
        accessor: "details",
        // disableSortBy: true,
        Cell: (info) => {
          return (
            <MDBox alignItems="center" width={200} sx={{ cursor: 'pointer' }} onClick={() =>
                clickShowFollowUpHandler(
                  info.cell.row.original.id,
                  info.cell.row.original.clinicId.id
              )}>
              <MDTypography variant="caption" fontWeight="medium">
                {(info.cell.row.original.details.length > 50) 
                ? info.cell.row.original.details.slice(0, 50-1) + '...' 
                : info.cell.row.original.details}
              </MDTypography>
            </MDBox>
          );
        },
      },
      {
        Header: t("commingDate"),
        accessor: "commingDate",
        // disableSortBy: true,
        Cell: (info) => {
          return (
            <MDBox display='grid' sx={{ cursor: 'pointer' }}>
              <MDTypography 
                variant="button" 
                fontWeight="medium" 
                onClick={() =>
                  clickShowFollowUpHandler(
                    info.cell.row.original.id,
                    info.cell.row.original.clinicId.id
                )}>
                  {info.cell.row.original.commingDate?.split(" ")[0]}
              </MDTypography>
              <MDTypography 
                variant="caption" 
                fontWeight="medium" 
                onClick={() =>
                  clickShowFollowUpHandler(
                    info.cell.row.original.id,
                    info.cell.row.original.clinicId.id
                )}>
                  {info.cell.row.original.commingDate?.split(" ")[1]}
              </MDTypography>
            </MDBox>
          )}
      },
      {
        Header: t("responsible"),
        accessor: "responsible",
        // disableSortBy: true,
        Cell: (info) => {
          return (
            <MDBox sx={{ cursor: 'pointer' }} onClick={() =>
                clickShowFollowUpHandler(
                  info.cell.row.original.id,
                  info.cell.row.original.clinicId.id
              )}>
              <ProductCell
                image={info.cell.row.original.responsible.avatar ? `${baseUrl}assets/${info.cell.row.original.responsible.avatar}` : null}
                name={info.cell.row.original.responsible.first_name}
                onClick={() =>
                clickShowFollowUpHandler(
                  info.cell.row.original.id,
                  info.cell.row.original.clinicId.id
              )}
              />
            </MDBox>
          );
        },
      },
      {
        Header: t("campaignData"),
        accessor: "campaignData",
        // disableSortBy: true,
        Cell: (info) => {
          return (
              <MDTypography 
                variant="button" 
                fontWeight="medium"
                sx={{ cursor: 'pointer' }} 
                onClick={() =>
                  clickShowFollowUpHandler(
                    info.cell.row.original.id,
                    info.cell.row.original.clinicId.id
                )}>
                {(info.cell.row.original.campaignData.length > 50) 
                ? info.cell.row.original.campaignData.slice(0, 50-1) + '...' 
                : info.cell.row.original.campaignData}
              </MDTypography>
          );
        },
      },
      // { Header: t("clinics"), accessor: "clinic", width: "10%" },
      // { Header: t("smsource"), accessor: "smsource", width: "10%" },
      // { Header: "gclid", accessor: "gclid", width: "10%" },
      // { Header: "fbclid", accessor: "fbclid", width: "10%" },
      
      // {
      //   Header: t("actions"),
      //   disableSortBy: true,
      //   accessor: "",
      //   Cell: (info) => {
      //     return (
      //       <MDBox display="flex" alignItems="center">
      //         {(ability.can("delete", "appointments") || isAdmin) && (
      //           <Tooltip
      //             title={t("deleteAppointment")}
      //             onClick={(e) =>
      //               clickDeleteHandler(e, info.cell.row.original.id)
      //             }
      //           >
      //             <IconButton>
      //               <DeleteIcon />
      //             </IconButton>
      //           </Tooltip>
      //         )}
      //         {(ability.can("edit", "appointments") || isAdmin) && (
      //           <Tooltip
      //             title={t("viewFollowUp")}
      //             onClick={() =>
      //               clickShowFollowUpHandler(
      //                 info.cell.row.original.id,
      //                 info.cell.row.original.clinicId.id
      //               )
      //             }
      //           >
      //             <IconButton>
      //               <ShowIcon />
      //             </IconButton>
      //           </Tooltip>
      //         )}
      //       </MDBox>
      //     );
      //   },
      // },
    ],
    rows: tableData,
  };


  const getCsvRows = (info) => {
    console.log(info.length);
    let updatedInfo = info.map((row) => {
      let csv={
          id: row.id,
          date_created: moment(row.date_created).add(-3,'hour').format(format1),
          responsible: `${row.responsible_id.first_name} ${row.responsible_id.last_name?row.responsible_id.last_name:""}`,
          pationt: row.pationt_id.first_name,
          pationtPhone: row.pationt_id.phone,
          clinic: row.clinic_id.name,
          smsource: row.source_id.name,
          details: row.details,
          gclid: row.gclid,
          fbclid: row.fbclid,
          campaign: row.campaign,
          source: row.source,
          medium: row.medium,
          term: row.term,
          content: row.content,
          status: row.status_id.name,
      };
      const keys = Object.keys(csv);
      let csvObj={}
      keys.forEach((element) => {
        csvObj[t(element)]=csv[element];
      })
      return csvObj;
    });
    return updatedInfo;
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {/* {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )} */}
      <MDBox pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {t("appointmentManagement")}
              </MDTypography>
              {isAdmin && <MDBox display="flex">
                  <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={()=>setShowFilter(!showFilter)}
                  sx={{mx:1}}
                >
                   {t("filter")}
                </MDButton>
                <MDBox sx={{ cursor: 'pointer' }}>
                  <CsvDownloadButton
                    sx={{ cursor: 'pointer' }}
                    data={getCsvRows(data)}
                    style={{ backgroundColor: 'rgb(37 37 39)',color: '#fff', fontSize : "15px" , fontWeight : "bold", padding:"6px 24px", borderRadius:"6px"}}
                    delimiter = ","
                  >
                    {t('export')}
                  </CsvDownloadButton>
                </MDBox>
              </MDBox>}
            </MDBox>
            {showFilter && <FilterAppointment
              setFilterUrl={setFilterUrl}
              filterUrl={defaultFilterUrl}
              clinicId={clinicId}
            />}
            <MDBox mt={1.5} px={3}>
              <Grid container spacing={3}>
                {statusStatistics.map((x) => (
                  <Grid item xs={12} md={6} lg={2} key={Math.random()}>
                    <MDBox mb={1.5}
                      sx={{ cursor: 'pointer' }}
                      onClick={()=>{
                        setFromStatistics(true);
                        // setFilterUrl(
                        //   `${x.element ? `&filter[status_id][_eq]=${x.element.id}`:``}`
                        // );
                        setFilterUrl1(
                          `${x.element ? `&filter[status_id][_eq]=${x.element.id}`:``}`
                        );
                    }}>
                      <ComplexStatisticsCard
                        title={{ text: x.name }}
                        count={x.count}
                        percentage={{ color: x.color, text: x.percentage }}
                        icon={{ color: x.color, component: "paid" }}
                      />
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </MDBox>
            <DataTable
              setLimitChange={setLimitChange}
              table={dataTableData}
              meta={meta}
              setPage={setPage}
              setSortVal={setSortVal}
              pageNum={page}
            />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AppointmentManagement;
